import { StandaloneSearch } from 'base/components/Search';
import { useMemo } from 'react';

export const ElleStandaloneSearch: typeof StandaloneSearch = ({ ...props }) => {
  const isAuthorPage = props.pageType === 'authorPage';
  const headline = useMemo(() => {
    if (isAuthorPage) {
      return <h1>{`Artiklar av "${props.query || ''}"`}</h1>;
    }

    return <h1>{`Sökresultat för "${props.query || ''}"`}</h1>;
  }, [props.pageType]);

  return <StandaloneSearch {...props} button={isAuthorPage ? null : 'LADDA IN FLER ARTIKLAR'} headline={headline} />;
};
