import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const Image = tw.theme({
  extend: ImageTheme,
  base: `aspect-default`,
});

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    caption: `text-preview-sm uppercase text-gray-800`,
    description: `text-preview-sm`,
    headline: `font-primary md:container-col-lg-8:text-headline-lg`,
  },
  variants: {
    variant: {
      horizontal: {
        base: `grid-cols-1 md:auto-rows-min md:grid-cols-2 md:gap-4`,
        description: `sm:container-col-lg-7:text-preview-lg sm:container-col-lg-7:mt-3`,
        headline: `text-headline-xs sm:text-headline-sm sm:container-col-lg-7:text-headline-sm`,
      },
      vertical: {
        base: `gap-3`,
        description: `sm:text-preview-lg`,
        headline: `container-col-sm-4:text-headline-2xs text-headline-sm container-col-sm-7:text-headline-sm container-col-sm-8:text-headline-sm sm:text-headline-sm sm:container-col-lg-4:text-headline-sm sm:container-col-lg-7:text-headline-sm md:container-col-lg-4:leading-[1.15] md:container-col-lg-4:text-headline-sm md:container-col-lg-8:leading-[1.05]`,
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
